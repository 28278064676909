<template>
  <div>
    <template
      v-if="isWxApplets"
      :style="{ marginTop: isWxApplets ? '46px' : '' }"
    >
      <van-nav-bar
        title="评论列表"
        fixed
        left-arrow
        @click-left="onClickLeft"
      />
    </template>
    <!-- <div style="height: 25px; background: #fff"></div>
    <div class="head">
      全部评论
      <img
        class="left"
        @click="$router.back(-1)"
        src="../../../assets/ShopImg/left.png"
        alt=""
      />
    </div> -->

    <div class="outisde">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getQueryGoodsEvaluation"
        v-if="evaluationList.length > 0"
      >
        <div
          class="comment_content"
          v-for="(item, i) in evaluationList"
          :key="i"
        >
          <!-- 资料 -->
          <div class="user">
            <img :src="hostUrl + item.HeadImg" alt="" />
            <div class="user_name">
              <p class="decimalPoint">{{ item.StudentName }}</p>
              <p>
                {{
                  getDateDiff(getDateTimeStamp(item.EvaluateTime)) +
                  " | " +
                  item.SpecName
                }}
              </p>
            </div>
          </div>

          <!-- 内容 -->

          <div class="comment_cont">
            {{ item.Evaluation }}
          </div>

          <!-- 图片 -->
          <div class="image" v-if="item.EvaluationImg">
            <div
              class="imgs"
              v-for="(item, i) in item.EvaluationImg.split(',')"
              :key="i"
            >
              <img :src="item" alt="" />
            </div>
          </div>
        </div>
      </van-list>
      <div v-else>
        <nocontent name="暂无评论"></nocontent>
      </div>
    </div>
  </div>
</template>

<script>
import { queryGoodsEvaluation } from "@/api/shop";
import nocontent from "@/views/shop/components/nocontent";
export default {
  components: {
    nocontent,
  },
  data() {
    return {
      evaluationList: [],
      pageIndex: 1,
      pageSize: 10,
      goodsId: 0,
      isEnd: false,
      loading: false,
      finished: false,
      hostUrl: "",
      isWxApplets: true,
    };
  },
  created() {
    //判断是否是微信浏览器的函数
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      this.isWxApplets = false;
    }
    this.goodsId = this.$route.params.id;
    this.hostUrl = this.GLOBAL.hostUrl;
  },
  mounted() {
    this.getQueryGoodsEvaluation();
    // window.addEventListener("scroll", this.listenScroll);
  },
  methods: {
    // 顶部返回
    onClickLeft() {
      window.history.go(-1);
    },
    //滚动
    listenScroll() {
      let that = this;
      let ele = document.documentElement;
      let scr = ele.scrollTop; // 向上滚动的那一部分高度
      let clientHeight = ele.clientHeight; // 屏幕高度也就是当前设备静态下你所看到的视觉高度
      let scrHeight = ele.scrollHeight; // 整个网页的实际高度，兼容Pc端
      if (scr + clientHeight + 5 >= scrHeight) {
        // console.log(that.isEnd);
        // if (that.isLoad == false||this.isEnd == false) {
        if (that.isEnd == false) {
          // that.isLoad = true;
          that.pageIndex++;
          that.getQueryGoodsEvaluation();
        }
      }
    },
    // 获取评论列表
    async getQueryGoodsEvaluation() {
      let params = {};
      params.GoodsId = this.goodsId;
      params.pageIndex = this.pageIndex;
      params.pageSize = this.pageSize;
      const res = await queryGoodsEvaluation(params);
      this.evaluationList = this.evaluationList.concat(res.response.data);
      console.log(this.evaluationList, 8888);
      this.page++;
      // 加载状态结束
      this.loading = false;
      // 数据全部加载完成
      if (this.evaluationList.length >= res.response.dataCount) {
        this.finished = true;
      }
    },
    getDateTimeStamp(dateStr) {
      return Date.parse(dateStr.replace(/-/gi, "/"));
    },
    getDateDiff(dateTimeStamp) {
      let result = "";
      var minute = 1000 * 60;
      var hour = minute * 60;
      var day = hour * 24;
      var halfamonth = day * 15;
      var month = day * 30;
      var now = new Date().getTime();
      var diffValue = now - dateTimeStamp;
      if (diffValue < 0) {
        //若日期不符则弹窗口告之,结束日期不能小于开始日期！
      }
      var monthC = diffValue / month;
      var weekC = diffValue / (7 * day);
      var dayC = diffValue / day;
      var hourC = diffValue / hour;
      var minC = diffValue / minute;
      if (monthC >= 1) {
        result = parseInt(monthC) + "个月前";
      } else if (weekC >= 1) {
        result = parseInt(weekC) + "周前";
      } else if (dayC >= 1) {
        result = parseInt(dayC) + "天前";
      } else if (hourC >= 1) {
        result = parseInt(hourC) + "小时前";
      } else if (minC >= 1) {
        result = parseInt(minC) + "分钟前";
      } else result = "刚刚";
      return result;
    },
  },
  beforeDestroy() {
    // window.removeEventListener("scroll", this.listenScroll);
  },
};
</script>

<style lang="scss" scoped>
.head {
  height: 50px;
  line-height: 50px;
  width: 100%;
  text-align: center;
  color: #333;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  background: #fff;

  .left {
    width: 22px;
    height: 17px;
    position: absolute;
    left: 4%;
    top: 50%;
    transform: translateY(-50%);
  }
}

.outisde {
  width: 100%;
  background: #fff;
  padding: 0 15px;
}

.comment_content {
  padding-bottom: 15px;
  padding-top: 10px;
  border-bottom: 1px solid #e8e8e8;
  width: 100%;

  .user {
    display: flex;
    align-items: center;

    img {
      width: 42px;
      height: 42px;
      border-radius: 50%;
    }

    .user_name {
      margin-left: 10px;
    }

    .user_name p:first-child {
      font-size: 14px;
      width: 100px;
      color: #333333;
      font-weight: 400;
    }

    .user_name p:last-child {
      font-size: 12px;
      color: #999999;
      font-weight: 400;
    }
  }

  .comment_cont {
    font-size: 14px;
    color: #333333;
    font-weight: 400;
    line-height: 20px;
    margin-top: 12px;
  }

  .image {
    margin-top: 15px;
    display: flex;

    .imgs {
      position: relative;
      width: 20%;
      padding-top: 20%;
      margin-right: 5%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }
    }
  }
}
/deep/ .van-nav-bar .van-icon {
  color: #000;
  font-size: 20px;
}
</style>
